import React from "react";

export const PageLoader = () => {
  const loadingImg = "https://cdn.furrylogin.net/logo-trans-bg.png";

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};
