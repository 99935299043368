import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src="https://cdn.furrylogin.net/logo-trans-bg.png"
          alt="FurryLogin Logo"
        />
      </NavLink>
    </div>
  );
};
