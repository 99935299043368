import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }: { handleClick: () => void }) => {

  return (
    <div className="mobile-nav-bar__tabs">
    <MobileNavBarTab
        path="/"
        label="Home"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/developers"
        label="Developers"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/about-us"
        label="About Us"
        handleClick={handleClick}
      />
    </div>
  );
};
