import React from "react";
import { PageLayout } from "../components/page-layout";
const scopes = [
  {
    name: 'openid',
    description: 'Default scope for OpenID, returns no information.'
  },
  {
    name: 'profile',
    description: 'Returns the user\'s display name and username.'
  },
  {
    name: 'email',
    description: 'Returns the user\'s email address.'
  },
  {
    name: 'real_name',
    description: 'Returns the user\'s real name.'
  },
  {
    name: 'is_mature',
    description: 'Returns if the user is above 18 years old.'
  },
  {
    name: 'social',
    description: 'Returns the verified social profiles of the user.'
  },
];

export const Developers = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Developers
        </h1>
        <p className="content__body">
            We are currently working on a developer portal. Please check back
            later. You can find our developer terms <a className="link" href="/developer-terms">here</a>.
        </p>
        <p className="content__body">
            For now, if you have a application you would like to connect, please contact us at <a className="link" href="mailto:support@furrylogin.net">support@furrylogin.net</a>. We support OAuth2, OpenID Connect, and SAML.
        </p>
        <h2 className="content__title">
          Scopes / Information
        </h2>
        <p className="content__body">
            Scopes can be used to request specific user information, please limit the amount of scopes you need. A user always needs to provide consent for the scopes you request.<br />
            We currently support the following scopes:
            <ul>
            {scopes.map(scope => (
              <li className="content__body"><span className="scope">{scope.name}</span> - {scope.description}</li>
            ))}
            </ul>
        </p>
        <p className="content__body">
          All information can be found in the JWT token of the user. The token is signed with our private key, and can be verified with our public key in the JWKS URL of your application.
        </p>
      </div>
    </PageLayout>
  );
};
