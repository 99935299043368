import React from "react";

export const LoginButton = () => {

  return (
    <button className="button__login" onClick={() => window.location.href = "https://auth.furrylogin.net"}>
      Log In
    </button>
  );
};
