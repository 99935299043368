import React from "react";

export const HeroBanner = () => {
  const logo = "https://cdn.furrylogin.net/logo-trans-bg.png";

  return (
    <div className="hero-banner hero-banner--pink-yellow">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="FurryLogin Logo" />
      </div>
      <h1 className="hero-banner__headline">Hello, We are FurryLogin!</h1>
      <p className="hero-banner__description">
        We created a SSO (Single sign on) provider for furries, now you can use one account for all your needs.
      </p>
      <a
        id="link-about-us"
        href="/about-us"
        className="button button--secondary"
      >
        Read the about us →
      </a>
    </div>
  );
};
