import React from "react";
import { PageLayout } from "../components/page-layout";

const contributors = [
  {
    name: 'Arumar',
    role: 'Software Engineer',
    description: 'Creating the fluffies tools.',
    image: 'https://arumar.art/_data/i/upload/2023/09/08/20230908024716-237ff557-me.png'
  },
  {
    name: 'bytefighter',
    role: 'Software Engineer',
    description: '',
    image: 'https://cdn.furrylogin.net/bytefighter.png'
  },
];

export const AboutUs = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <div className="about-us">
        <h1 id="page-title" className="content__title">
          About Us
        </h1>
      <div className="contributors">
        {contributors.map(contributor => (
          <div key={contributor.name} className="contributor">
            <img className="profile-image" src={contributor.image} alt={contributor.name} />
            <div>
              <h2 className="content__title">{contributor.name}</h2>
              <p className="content__body">{contributor.role}</p>
              {/*<p className="content__body">{contributor.description}</p>*/}
            </div>
          </div>
        ))}
      </div>
      <h2 className="content__title">Why?</h2>
    <p className="content__body">
      We created FurryLogin to make it easier for furries to login to websites and apps created by the fandom without relying on a third party like Telegram. We also want to make it easier for developers to create apps and websites for the fandom.
      </p>
    <h2 className="content__title">Contact us</h2>
    <p className="content__body">
      If you have any questions or feedback, you can contact us via email at <a className="link" href="mailto:support@furrylogin.net">support@furrylogin.net</a>
      </p>

    </div>
      </div>
      
    </PageLayout>
  );
};
