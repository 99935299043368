import React from "react";
import { PageLayout } from "../components/page-layout";

export const PrivacyPolicy = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Privacy policy
        </h1>
        <p className="content__body">
        This Privacy Policy governs the manner in which our Single Sign-On (SSO) service collects, uses, maintains, and discloses information collected from users (each, a "User") of FurryLogin.
        </p>
        <h2 className="content__title">Personal Identification Information:</h2>
        <p className="content__body">FurryLogin collects the following personal identification information from users:</p>
        <ul>
          <li className="content__body">Real name</li>
          <li className="content__body">Email address</li>
          <li className="content__body">Nickname</li>
          <li className="content__body">Date of birth</li>
          <li className="content__body">Social profiles (optional)</li>
        </ul>
        <p className="content__body">It is important to note that any information shared with external services requires you to give consent.</p>

        <h2 className="content__title">How We Use Collected Information:</h2>
        <p className="content__body">FurryLogin may collect and use personal information for the following purposes:</p>
        <ol>
          <li className="content__body">Authentication: To verify user identity and enable access to associated accounts.</li>
          <li className="content__body">Customization: To personalize the user experience based on provided information.</li>
          <li className="content__body">Communication: To send emails regarding service updates, account notifications, etc.</li>
          <li className="content__body">Improvement: To analyze usage patterns and enhance the functionality and performance of the service.</li>
          <li className="content__body">Security: To maintain the security and integrity of user accounts and the service.</li>
        </ol>

        <h2 className="content__title">Sharing Your Personal Information:</h2>
        <p className="content__body">FurryLogin does not sell, trade, or rent users' personal identification information to others. However, certain information may be shared with external services upon user confirmation.</p>

        <h2 className="content__title">Data Security:</h2>
        <p className="content__body">We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our service.</p>

        <h2 className="content__title">Changes to This Privacy Policy:</h2>
        <p className="content__body">FurryLogin has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect.</p>

        <h2 className="content__title">Your Acceptance of These Terms:</h2>
        <p className="content__body">By using this service, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our service. Your continued use of the service following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

        <h2 className="content__title">Contacting Us:</h2>
        <p className="content__body">If you have any questions about this Privacy Policy, the practices of this service, or your dealings with this service, please contact us at <a className="link" href="mailto:support@furrylogin.net">support@furrylogin.net</a>.</p>

        <p className="content__body">This Privacy Policy was last updated on 29-01-2024.</p>
      </div>
    </PageLayout>
  );
};
