import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { AboutUs } from "./pages/about-us";
import { DeveloperTerms } from "./pages/developer-terms";
import { Developers } from "./pages/developers";

export const App = () => {
  const isLoading = false;

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
   <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/developers" element={<Developers />} />
      <Route path="/developer-terms" element={<DeveloperTerms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
