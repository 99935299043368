import React from "react";
import { LoginButton } from "../../buttons/login-button";

export const MobileNavBarButtons = () => {

  return (
    <div className="mobile-nav-bar__buttons">
        <>
          <LoginButton />
        </>
    </div>
  );
};
