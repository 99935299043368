import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {

  return (
    <div className="nav-bar__tabs">
    <NavBarTab 
      path="/developers"
      label="Developers" />
      <NavBarTab 
        path="/about-us"
        label="About Us" />
    </div>
  );
};
