import React from "react";
import { PageLayout } from "../components/page-layout";

export const DeveloperTerms = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Developer Terms & Conditions
        </h1>
        <div className="content">
      <p className="content__body">
        These Terms and Conditions ("Terms") govern the relationship between developers ("Developer," "You," or "Your") and FurryLogin ("We," "Us," or "Our") regarding the development of clients for our Single Sign-On (SSO) service ("Service"). By developing clients for our SSO Service, you agree to be bound by these Terms.
      </p>
      <h3 className="content__title">1. License:</h3>
      <p className="content__body">
        Subject to compliance with these Terms, FurryLogin grants Developer a non-exclusive, non-transferable, revocable license to develop clients for the Service.
      </p>
      <h3 className="content__title">2. Use of FurryLogin's Resources:</h3>
      <p className="content__body">
        Developer agrees to use FurryLogin's resources, including but not limited to APIs, documentation, and trademarks, solely for the purpose of developing clients for the Service.
      </p>
      <h3 className="content__title">3. Compliance with Guidelines:</h3>
      <p className="content__body">
        Developer agrees to comply with all guidelines, specifications, and requirements provided by FurryLogin for the development of clients for the Service.
      </p>
      <h3 className="content__title">4. Intellectual Property:</h3>
      <p className="content__body">
        Developer acknowledges that all intellectual property rights, including but not limited to copyrights, trademarks, and patents, related to the Service and FurryLogin's resources belong solely to FurryLogin.
      </p>
      <h3 className="content__title">5. Restrictions:</h3>
      <p className="content__body">
        Developer shall not:
        <ul className="content__body">
          <li className="content__body">Use the Service or FurryLogin's resources for any unlawful or unauthorized purpose.</li>
          <li className="content__body">Modify, adapt, or create derivative works based on the Service or FurryLogin's resources.</li>
          <li className="content__body">Reverse engineer, decompile, or disassemble the Service or FurryLogin's resources.</li>
          <li className="content__body">Sell, lease, sublicense, or otherwise transfer the Service or FurryLogin's resources to any third party.</li>
        </ul>
      </p>
      <h3 className="content__title">6. Support and Maintenance:</h3>
      <p className="content__body">
        Developer is solely responsible for providing support and maintenance for the clients developed for the Service.
      </p>
      <h3 className="content__title">7. Security:</h3>
      <p className="content__body">
        Developer agrees to implement appropriate security measures to protect against unauthorized access, use, or disclosure of any data or information related to the Service.
      </p>
      <h3 className="content__title">8. Indemnification:</h3>
      <p className="content__body">
        Developer agrees to indemnify, defend, and hold harmless FurryLogin, its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, damages, liabilities, losses, costs, and expenses arising out of or related to Developer's use of the Service or FurryLogin's resources.
      </p>
      <h3 className="content__title">9. Termination:</h3>
      <p className="content__body">
      FurryLogin reserves the right to terminate Developer's access to the Service and FurryLogin's resources at any time without prior notice for any reason.
      </p>
      <h3 className="content__title">10. Modification of Terms:</h3>
      <p className="content__body">
      FurryLogin reserves the right to modify these Terms at any time. Developer's continued use of the Service or FurryLogin's resources after the effective date of any modifications constitutes acceptance of the modified Terms.
      </p>
      <h3 className="content__title">11. Governing Law:</h3>
      <p className="content__body">
        These Terms shall be governed by and construed in accordance with the laws of the Netherlands, without regard to its conflict of law principles.
      </p>
      <h3 className="content__title">12. Entire Agreement:</h3>
      <p className="content__body">
        These Terms constitute the entire agreement between Developer and FurryLogin regarding the subject matter hereof and supersede all prior or contemporaneous agreements, communications, and proposals, whether oral or written, between the parties with respect to such subject matter.
      </p>
    </div>
      </div>
    </PageLayout>
  );
};
